import { graphql, useStaticQuery } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

export const useActivitiesPageData = () => {
  const { prismicActivitiesPage, allPrismicActivity } = useStaticQuery(graphql`
    query {
      prismicActivitiesPage {
        _previewable
        id
        uid
        data {
          title {
            html
          }
          tagline {
            text
          }
            text {
                text
            }
          body {
            ... on PrismicActivitiesPageBodyBook {
              id
              slice_type
              primary {
                button_text
                link {
                  url
                }
                text {
                  text
                }
              }
            }
          }
        }
      }
      allPrismicActivity {
        nodes {
          uid
          data {
            title {
              text
            }
            text {
              html
            }
            button_text {
              text
            }
            schedule {
              url
            }
            image {
              alt
              fluid {
                aspectRatio
                sizes
                src
                srcSet
              }
            }
          }
          _previewable
          id
        }
      }
    }
  `)
  return { prismicActivitiesPage, allPrismicActivity }
}

export const getActivitiesPageData = (staticData, previewData) => {
  const mergedData: any = mergePrismicPreviewData({
    staticData,
    previewData,
    strategy: "rootReplaceOrInsert",
  })
  const activities = mergedData.allPrismicActivity.nodes.map(
    activity => activity.data
  )
  if (mergedData.prismicActivity) {
    activities.push(mergedData.prismicActivity.data)
  }
  const { uid, data } = mergedData.prismicActivitiesPage
  const bookData = data.body.find(section => section.slice_type === "book")
  const { title, tagline, text } = data

  return {
    uid,
    heading: {
      title,
      tagline,
      text
    },
    activities,
    bookData: bookData ? bookData.primary : undefined,
  }
}
